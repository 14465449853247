import { start, registerApplication } from 'single-spa';
import 'babel-polyfill';
import { envVars } from './envVars';
import { defaultCustomProps } from '.';

declare global {
  interface Window {
    System: { import: Function };
    singleSpaNavigate: Function;
  }
}

registerApplication(
  envVars.APP_NAME,
  window.System.import(`/funding-eligibility/${envVars.APP_NAME}.js`),
  location => location.pathname.startsWith('/preview'),
  {
    ...defaultCustomProps,
    basePath: '/preview',
  },
);

if (!window.location.pathname || window.location.pathname === '/') {
  window.singleSpaNavigate('/preview');
}

start();
